import { Loader } from '@arrive/components/loader';
import { useAuth0 } from '@auth0/auth0-react';
import { ROLE_SCHEMA, type Role, RolesConstants } from '@arrive/auth';
import type { PropsWithChildren } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useLogout } from '@arrive/hooks/useLogout';

export function AuthenticationProvider({ children }: Readonly<PropsWithChildren<unknown>>) {
  const { isAuthenticated, isLoading, error, loginWithRedirect, user } = useAuth0();
  const logout = useLogout();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Loader />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh">
        <Typography>Something went wrong.</Typography>
        <Box mt={2}>
          <Typography>{error}</Typography>
        </Box>
      </Box>
    );
  }

  if (!isAuthenticated || !user) {
    loginWithRedirect({ redirectUri: window.location.href });
    return null;
  }

  if (
    !user[ROLE_SCHEMA].map((i: Role) => i.toLowerCase()).some((val: Role) =>
      RolesConstants.map((i: Role) => i.toLowerCase()).includes(val)
    )
  ) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh">
        <Typography>Access not allowed</Typography>
        <Box mt={2}>
          <Button onClick={logout} variant="outlined">
            Logout
          </Button>
        </Box>
      </Box>
    );
  }

  return <>{children}</>;
}
