import { Suspense, useMemo } from 'react';
import { AuthorizationProvider } from '@arrive/auth';
import RelayEnvironment from './RelayEnvironment';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { getLayoutRoutes, routesToExclude } from '@arrive/routes';
import { createTheme, ThemeProvider } from '@mui/material';
import ColorModeContext from 'contexts/ColorModeContext';
import { useLocalStorage } from 'usehooks-ts';
import { Loader } from '@arrive/components/loader';
import { SnackbarProvider } from 'notistack';
import { useIdleTimer } from 'react-idle-timer';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { useLogout } from './hooks/useLogout';

function App() {
  const logout = useLogout();

  const [mode, setMode] = useLocalStorage<'light' | 'dark'>('colorMode', 'light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    [setMode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode
        }
      }),
    [mode]
  );

  const sessionTimeoutEnabled = process.env.REACT_APP_WMS_SESSION_TIMEOUT_ENABLED || 'true';

  useIdleTimer({
    crossTab: true,
    startOnMount: sessionTimeoutEnabled === 'true',
    startManually: sessionTimeoutEnabled !== 'true',
    timeout: 15 * 60 * 1000, // 15 min
    syncTimers: 200,
    onIdle: () => {
      logout();
    }
  });

  const routes = getLayoutRoutes(routesToExclude);

  const RouterApp = () => useRoutes(routes);

  const ErrorFallback = ({ error }: FallbackProps) => (
    <div role="alert">
      <p>
        <b>Something went wrong</b>
      </p>
      <p>Looks like your internet is not working properly or the server is down</p>
      <pre>{error.message}</pre>
      <button
        type="button"
        onClick={() => {
          location.reload();
        }}>
        Try again
      </button>
    </div>
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <RelayEnvironmentProvider environment={RelayEnvironment}>
              <Suspense fallback={<Loader />}>
                <AuthorizationProvider>
                  <BrowserRouter>
                    <RouterApp />
                  </BrowserRouter>
                </AuthorizationProvider>
              </Suspense>
            </RelayEnvironmentProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
